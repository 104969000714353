<template>
  <div class="swiperBox">
    <div class="swiperList">
      <!-- <div>
        <p v-if="webshine_photowall" class="stitle">
          {{ webshine_photowall.cardTitle }}
        </p>
      </div> -->
      <div class="about">
        <div class="left_yuan" @click="cardPunt()">
          <i class="el-icon-arrow-left"></i>
        </div>
        <el-carousel
          ref="nop"
          :interval="4000"
          :loop="true"
          type="card"
          indicator-position="none"
          :autoplay="true"
          arrow="never"
          height="216px"
        >
          <el-carousel-item
            v-for="(item, index) in webshine_photowall.tbCardListModelList"
            :key="index"
          >
            <div class="talkCard">
              <img :src="item.cardImage" />
            </div>
          </el-carousel-item>
          <div class="back_ground"></div>
        </el-carousel>
        <div class="right_yuan" @click="cardNext()">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcardList } from "@/api/commonApi";

export default {
  name: "ShineSwiper",
  data() {
    return {
      webshine_photowall: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 方程式师兄师姐说
    getData() {
      // getcardList(["webshine_photowall"]).then(([res]) => {
      //   console.log(res);
      //   if (res.success && res.data) {
      // this.webshine_photowall = res.data.webshine_photowall;
      this.webshine_photowall = {
        id: "160",
        parentId: "0",
        cardTitle: "Shine校园招募计划照片墙",
        cardContent: null,
        cardImage: null,
        cardIndex: 0,
        linkUrl: null,
        cardType: "webshine_photowall",
        cardStarted: 1,
        deleted: 0,
        detailsUrl: null,
        cardVideo: null,
        updateTime: null,
        empty: false,
        tbCardListModelList: [
          {
            id: "161",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/161tb_card_list.jpeg",
            cardIndex: 1,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "162",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/162tb_card_list.jpeg",
            cardIndex: 2,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "163",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/163tb_card_list.jpeg",
            cardIndex: 3,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "164",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/164tb_card_list.jpeg",
            cardIndex: 4,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "165",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/165tb_card_list.jpeg",
            cardIndex: 5,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "166",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/166tb_card_list.jpeg",
            cardIndex: 6,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "167",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/167tb_card_list.jpeg",
            cardIndex: 7,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "168",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/168tb_card_list.jpeg",
            cardIndex: 8,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "169",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/169tb_card_list.jpeg",
            cardIndex: 9,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "170",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/170tb_card_list.jpeg",
            cardIndex: 10,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "171",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/171tb_card_list.jpeg",
            cardIndex: 11,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
          {
            id: "172",
            parentId: "160",
            cardTitle: null,
            cardContent: null,
            cardImage:
              "https://cdn-campus.nio.com/nio-campus-service/2022/07/26/172tb_card_list.jpeg",
            cardIndex: 12,
            cardType: "webshine_photowall",
            cardStarted: 1,
            deleted: 0,
            detailsUrl: null,
            cardVideo: null,
            updateTime: null,
            empty: false,
            tbCardListModelList: [],
            createTime: null,
            linkUrl: null,
          },
        ],
        createTime: null,
      };
      //   }
      // });
    },
    cardPunt() {
      this.$refs.nop.prev();
    },
    cardNext() {
      this.$refs.nop.next();
    },
  },
};
</script>
<style scoped lang="less">
.swiperBox {
  background: #fafdfd;
  width: 100%;
  height: auto;
  padding: 80px 0;
  .stitle {
    font-size: 24px;
    color: rgba(4, 11, 41, 1);
    margin-bottom: 26px;
    text-align: center;
  }
}
.swiperList {
  width: 80vw;
  min-width: 1000px;
  margin: 0 auto;
  .about {
    position: relative;
  }
}
.el-carousel--horizontal {
  width: 60vw;
  min-width: 724px;
  margin: 0px auto;
}
.right_yuan {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 38px;
  width: 38px;
  line-height: 38px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: #f1f4f4;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 0px;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
  i:before {
    color: #41475e;
  }
}
.right_yuan:hover,
.left_yuan:hover {
  background-color: #aeaeae;
}
.left_yuan {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 38px;
  width: 38px;
  line-height: 38px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: #f1f4f4;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
  i:before {
    color: #41475e;
  }
}

.talkCardShadow:hover {
  cursor: pointer;
}
.talkCard {
  justify-content: space-between;
  position: relative;
  border-radius: 4px;

  margin-right: 16px;

  background-color: #fff;
  overflow: hidden;
}
img {
  height: 216px;
  width: 100%;
  object-fit: cover;
  // border: 1px solid;
}
.cardText {
  .cardTitle {
    font-size: 16px;
    margin: 8px 16px;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #040b29;
  }
  .cardInfo {
    margin: 8px 16px;
    font-size: 12px;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #41475e;
    white-space: nowrap;
  }
}
</style>
